
export default {
  name: 'SliderArrows',
  props: {
    fraction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        carrot: 'arrow-small',
      },
    }
  },
}
