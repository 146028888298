
import CustomImage from '~/components/elements/CustomImage';
import {phraseDeclension} from '~/plugins/formatting';

export default {
  name: 'BlogCardAuthor',
  components: {CustomImage},
  props: {
    item: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
  computed: {
    totalArticlesPhrase() {
      return phraseDeclension(this.item.articleCount, ['статья', 'статей', 'статьи']);
    },
  },
}
